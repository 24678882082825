import Header from "../components/Header";
import Contact from "../components/Contact";
import Footer from "../components/Footer";

const ContactPage = () => {
    return (
        <>
            <Header />
            <Contact />
            <Footer />
        </>
    );
    }

export default ContactPage;